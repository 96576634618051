<template>
  <page-loader v-if="loading" />
  <div v-else class="question-steps th-mt-24">
    <div v-for="(step, sKey) in progress" :key="sKey">
      <div
        class="d-flex align-items-center question-steps__step"
        :class="{ active: currentStep === sKey + 1 }"
      >
        <v-icon v-if="step.exists" color="success" size="24" class="th-mr-8">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else color="primary" size="24" class="th-mr-8">
          mdi-plus-circle
        </v-icon>
        <span
          v-html="$translation.t(`questionnaire.progress.${step.type}`)"
        ></span>
      </div>
      <question-steps
        v-if="currentStep === sKey + 1"
        class="question-steps__sub-steps"
        step-class="question-steps__sub-step"
      />
    </div>
  </div>
</template>

<script>
import PageLoader from "../../PageLoader";
import QuestionSteps from "./QuestionSteps";
import { mapState } from "vuex";

export default {
  name: "ProductSteps",
  components: { QuestionSteps, PageLoader },
  data: () => ({
    title: "Document",
    loading: true,
  }),
  created() {
    this.updateProgress();
    this.loading = false;
  },
  computed: {
    currentStep() {
      return (
        this.progress.findIndex(
          ({ type }) => type === this.$route.params.type
        ) + 1
      );
    },
    ...mapState("business", ["progress"]),
  },
  methods: {
    async updateProgress() {
      await this.$store.dispatch("business/fetchBusinessProgress");
    },
  },
  watch: {
    currentStep() {
      this.updateProgress();
    },
  },
};
</script>
